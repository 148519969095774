
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJSZXZpZXcgeW91ciBwcmVmZXJlbmNlcyI6IlJldmlldyB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiW8em4bq94bq94bq94bmvIMOf4bq94bq94bq94bmv4bmv4bq94bq94bq9xZkgxLXDtsO2w7bDnyBtzILEg8SDxIPhua%2FDp%2BG4qeG6veG6veG6vcWhXSIsIlJlZmluZWQgZm9yIHlvdSI6IlvFmOG6veG6vcaSw6zDrOC4geC4teC5ieG6veG6vcaMIMaSw7bDtsWZIMO9w73DtsO2x5rHml0iLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IlvhuIrhur3hur3GksOsw6zguIHguLXguYnhur3hur0gw73DvcO2w7bHmseaxZkgxqXFmeG6veG6vcaS4bq94bq9xZnhur3hur3guIHguLXguYnDp%2BG6veG6vcWhXSIsIlJldmlldyB5b3VyIHByZWZlcmVuY2VzIjoiW8WY4bq94bq94bm9w6zDrOG6veG6vcW1IMO9w73DtsO2x5rHmsWZIMalxZnhur3hur3GkuG6veG6vcWZ4bq94bq94LiB4Li14LmJw6fhur3hur3FoV0iLCJCZXRhIjoiW%2BG6nuG6veG6veG5r8SDxINdIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiW8em4bq94bq94bq94bmvIMOf4bq94bq94bq94bmv4bmv4bq94bq94bq9xZkgxLXDtsO2w7bDnyBtzILEg8SDxIPhua%2FDp%2BG4qeG6veG6veG6vcWhXSIsIlJlZmluZWQgZm9yIHlvdSI6IlvFmOG6veG6vcaSw6zDrOC4geC4teC5ieG6veG6vcaMIMaSw7bDtsWZIMO9w73DtsO2x5rHml0iLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IlvhuIrhur3hur3GksOsw6zguIHguLXguYnhur3hur0gw73DvcO2w7bHmseaxZkgxqXFmeG6veG6vcaS4bq94bq9xZnhur3hur3guIHguLXguYnDp%2BG6veG6vcWhXSIsIlJldmlldyB5b3VyIHByZWZlcmVuY2VzIjoiW8WY4bq94bq94bm9w6zDrOG6veG6vcW1IMO9w73DtsO2x5rHmsWZIMalxZnhur3hur3GkuG6veG6vcWZ4bq94bq94LiB4Li14LmJw6fhur3hur3FoV0iLCJCZXRhIjoiW%2BG6nuG6veG6veG5r8SDxINdIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    