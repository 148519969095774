import { useCallback, useEffect, useMemo, useState } from 'react';

import type { CareerFeedAssistantQuestionSuggestion } from './CareerFeedAssistantQuestions';

type UseSuggestionsType = {
  multiSelectSuggestions: CareerFeedAssistantQuestionSuggestion[];
  otherOption: CareerFeedAssistantQuestionSuggestion[];
  skipOption: CareerFeedAssistantQuestionSuggestion[];
};
export const useSuggestions = (
  suggestions: CareerFeedAssistantQuestionSuggestion[],
) => {
  const {
    multiSelectSuggestions: _multiSelectSuggestions,
    otherOption: _otherOption,
    skipOption: _skipOption,
  } = useMemo(
    () =>
      suggestions.reduce<UseSuggestionsType>(
        (acc, suggestion) => {
          if (suggestion.showFreeTextField) {
            acc.otherOption.push(suggestion);
          } else if (suggestion.isSkipButton) {
            acc.skipOption.push(suggestion);
          } else {
            acc.multiSelectSuggestions.push(suggestion);
          }
          return acc;
        },
        {
          multiSelectSuggestions: [],
          otherOption: [],
          skipOption: [],
        },
      ),
    [suggestions],
  );

  const _singleOption = useMemo(
    () => [..._otherOption, ..._skipOption],
    [_otherOption, _skipOption],
  );

  const multiSelectSuggestions = useMultiSelectOptions(_multiSelectSuggestions);
  const singleOptions = useSingleSelectOptions(_singleOption);

  return useMemo(
    () => ({
      suggestions: [
        ...multiSelectSuggestions.options.map((option) => ({
          ...option,
          onToggleSelect: () => {
            option.onToggleSelect();
            singleOptions.clearSelected();
          },
          type: 'multiSelect',
        })),
        ...singleOptions.options.map((option) => ({
          ...option,
          onToggleSelect: () => {
            option.onToggleSelect();
            multiSelectSuggestions.clearSelected();
          },
          type: 'singleSelect',
        })),
      ],
      selectedSuggestions: [
        ...multiSelectSuggestions.options,
        ...singleOptions.options,
      ]
        .filter((option) => option.isSelected)
        .map((option) => option.data),
    }),
    [multiSelectSuggestions, singleOptions],
  );
};

export interface OptionsType<T> {
  options: Array<{
    data: T;
    isSelected: boolean;
    onToggleSelect: () => void;
  }>;
  clearSelected: () => void;
}

const useMultiSelectOptions = <T>(options: T[]): OptionsType<T> => {
  // This state is used to help determine should the selected suggestion be applied only when the suggestions are the same
  // Sideeffect: This will cause a minor delay after the options changed, user will not be able to select the new options until the state is ready. It will take ~40ms to update the state.
  const [previousOptions, setPreviousOptions] = useState<T[]>([]);
  const [selected, setSelected] = useState(
    new Array(options.length).fill(false),
  );

  const toggleSelected = useCallback((index: number) => {
    setSelected((prev) => {
      const newSelected = [...prev];
      newSelected[index] = !newSelected[index];
      return newSelected;
    });
  }, []);

  const clearSelected = useCallback(() => {
    setSelected((prev) =>
      prev.some(Boolean) ? new Array(options.length).fill(false) : prev,
    );
    setPreviousOptions(options);
  }, [options]);

  // Should reset all selected suggestions when suggestions change
  useEffect(() => clearSelected(), [clearSelected, options]);

  return useMemo(() => {
    const selectedStateIsReady = options === previousOptions;
    return {
      options: options.map((suggestion, index) => ({
        data: suggestion,
        onToggleSelect: selectedStateIsReady
          ? () => toggleSelected(index)
          : () => {},
        isSelected: selectedStateIsReady ? selected[index] : false,
      })),
      clearSelected,
    };
  }, [options, clearSelected, previousOptions, selected, toggleSelected]);
};

const useSingleSelectOptions = <T>(options: T[]): OptionsType<T> => {
  // This state is used to help determine should the selected suggestion be applied only when the suggestions are the same
  // Sideeffect: This will cause a minor delay after the options changed, user will not be able to select the new options until the state is ready. It will take ~40ms to update the state.
  const [previousOptions, setPreviousOptions] = useState<T[]>([]);
  const [selected, setSelected] = useState<number | null>(null);

  const toggleSelected = useCallback((index: number) => {
    setSelected((prev) => (prev === null || prev !== index ? index : null));
  }, []);

  const clearSelected = useCallback(() => setSelected(null), []);

  // Should reset selected suggestions when suggestions change
  useEffect(() => {
    clearSelected();
    setPreviousOptions(options);
  }, [clearSelected, options]);

  return useMemo(() => {
    const selectedStateIsReady = options === previousOptions;
    return {
      options: options.map((suggestion, index) => ({
        data: suggestion,
        onToggleSelect: selectedStateIsReady
          ? () => toggleSelected(index)
          : () => {},
        isSelected: selectedStateIsReady ? selected === index : false,
      })),
      clearSelected,
    };
  }, [options, previousOptions, clearSelected, selected, toggleSelected]);
};
